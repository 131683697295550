html {
    height: 100%;
}

body {
    font-family: 'Cantarell', sans-serif;
    height: 100%;

    #root {
        min-height: 100%;

        // .App {
        //     height: 100vh;
        // }
    }
}

.header {
    padding: 20px;
    display: flex;
    justify-content: space-around;

    @media (max-width: 767px) {
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        height: 200px;

    }
}

.hero {
    padding: 40px;
    display: flex;
    justify-content: space-around;

    &_card {
        width: 30%;
    }

    .about p {
        font-size: 20px;
        line-height: 40px;
        text-align: center;
    }

    @media (max-width: 767px) {
        flex-direction: column;
        width: 100%;

        &_card {
            width: 100%;
            margin-bottom: 20px;
        }

        .about p {
            font-size: 16px;
            line-height: 30px;
        }

        //CSS

    }

}


hr.cool {
    overflow: visible;
    /* For IE */
    padding: 0;
    border: none;
    text-align: center;
}

hr.cool:after {
    content: "§";
    display: inline-block;
    position: relative;
    top: -0.7em;
    font-size: 1.5em;
    padding: 0 0.25em;
    background: inherit;
}

.avatar {
    border-radius: 50%;
    padding: 5px;


    img {
        border-radius: inherit;

        &:hover {
            cursor: pointer;
        }
    }
}

.hero_card {
    display: flex;
    flex-direction: column;


    &__upper {
        display: inherit;
        flex-direction: inherit;
        align-items: center;
        padding: 20px;
    }

    &__lower {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 10px;

        .skills_button {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            flex-wrap: wrap;

            span {
                padding: 3px 5px;
                margin-bottom: 4px;

                &:hover {
                    background-color: #081229;
                    border: 2px solid #BF1650;
                    color: #BF1650;
                    cursor: pointer;
                }
            }
        }
    }
}

// box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
@import "card";


.lds-ripple {
    display: inline-block;
    position: relative;
    width: 180px;
    height: 180px;
}

.lds-ripple div {
    position: absolute;
    border: 4px solid #fff;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
}

@keyframes lds-ripple {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }

    100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
}

.social {
    width: 100%;

    &>h2 {
        text-align: center;
    }

    .social_icons {
        width: 100%;
        display: inline-flex;
        justify-content: center;
        margin: 0 auto 20px auto;

        &>a {
            margin-right: 20px;
        }

        &>a:hover {
            cursor: pointer;
            animation: shake 375ms infinite;
            animation-timing-function: linear;
        }


    }


}

.download {
    display: flex;
    margin: 0 auto;
    width: 20%;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;

    transition: transform 1s ease-in-out;

    &:hover {
        transform: scale(1.2);
        cursor: pointer;

    }

    box-icon {
        margin-right: 5px;
    }

    @media (max-width: 767px) {
        width: 80%;
    }
}

@keyframes shake {
    0% {
        transform: rotate(14.5deg);
    }

    50% {
        transform: rotate(-14.5deg);
    }

    100% {
        transform: rotate(14.5deg);
    }
}

.footer {
    padding: 100px 0 10px 0;

    .hr_container {
        width: 60%;
        margin: 0 auto;
    }

    p {
        margin-top: 10px;
        text-align: center;
    }

}

.mobile_email {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 15px;

    p {
        font-size: 20px;
        margin-bottom: 0;

        span {
            margin-right: 5px;
        }
    }
}

.built {
    display: inline-flex;
    width: 100%;
    justify-content: center;

    p {
        text-align: center;
    }
}



.cta {
    width: 200px;
    margin: 60px auto 0;
    -webkit-perspective: 1000;
    -webkit-backface-visibility: hidden;
    background: transparent;


    .pulse-button {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 50%;
        bottom: 50%;
        left: 50%;
        margin-left: -50px;
        margin-top: -50px;
        width: 100px;
        height: 100px;
        font-size: 1.3em;
        font-weight: light;
        font-family: 'Trebuchet MS', sans-serif;
        text-transform: uppercase;
        text-align: center;
        line-height: 100px;
        letter-spacing: -1px;
        color: white;
        border: none;
        border-radius: 50%;
        background: #BF1650;
        cursor: pointer;
        box-shadow: 0 0 0 0 #BF1650;
        -webkit-animation: pulse 1.5s infinite;
    }

    .pulse-button:hover {
        -webkit-animation: none;
    }
}

@keyframes pulse {
    0% {
        transform: scale(.9);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 50px rgba(#5a99d4, 0);
    }

    100% {
        transform: scale(.9);
        box-shadow: 0 0 0 0 rgba(#5a99d4, 0);
    }
}