$font : 'Sofia',
sans-serif;
$font-size:16px;
$blue : #0a4870;
$blue2 : #e3ebf1;
$black: #000;
$grey : #7d7d7d;
$grey2 : #f0f0f0;
$grey3 : #e8e7e7;
$grey4 : #fdfdfd;
$bluegrey : #49606e;
$orange: #ec992c;

@mixin radius($val) {
    -webkit-border-radius: $val;
    -moz-border-radius: $val;
    border-radius: $val;
}

@mixin cardsOpen() {
    &::before {
        background: rgba(10, 72, 112, 0.6);
    }

    .book-container {
        .content {
            opacity: 1;

            transform: translateY(0px);
        }
    }

    .informations-container {
        transform: translateY(0px);

        .more-information {
            opacity: 1;
        }
    }
}

* {
    margin: 0;
    padding: 0;

    font-family: $font;
}

h2 {
    color: #0a4870;
    font-weight: 500;
}

ul {
    display: flex;
    flex-wrap: wrap;

    list-style: none;
    padding: 0;
    justify-content: space-around;

    .booking-card {
        position: relative;
        width: 380px;
        display: flex;
        // flex: 0 0 300px;
        flex-direction: column;

        margin: 20px;
        margin-bottom: 30px;
        @include radius(10px);

        overflow: hidden;

        background-position: center center;
        background-size: cover;

        text-align: center;
        color: $blue;

        transition: .3s;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            background: rgba(10, 72, 112, 0);

            transition: .3s;
        }

        .book-container {
            height: 200px;

            .content {
                position: relative;
                opacity: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                width: 100%;

                transform: translateY(-200px);

                transition: .3s;

                .btn {
                    border: 3px solid white;
                    padding: 10px 15px;

                    background: none;

                    text-transform: uppercase;
                    font-weight: bold;
                    font-size: 1.3em;
                    color: white;

                    cursor: pointer;

                    transition: .3s;

                    &:hover {
                        background: white;

                        border: 0px solid white;

                        color: $blue;
                    }
                }
            }
        }

        .informations-container {
            flex: 1 0 auto;

            padding: 20px;

            background: $grey2;

            transform: translateY(180px);

            transition: .3s;

            .title {
                position: relative;

                padding-bottom: 10px;
                margin-bottom: 10px;

                font-weight: bold;
                font-size: 1.2em;

                &::after {
                    content: '';

                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;

                    height: 3px;
                    width: 50px;

                    margin: auto;

                    background: $blue;
                }
            }

            .pills {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: flex-start;
                padding: 20px;

                & span {
                    border-radius: 20px;
                    padding: 3px 5px;
                    margin-bottom: 2px;
                    margin-right: 3px;
                }
            }

        }

        &:hover {
            @include cardsOpen();
        }
    }
}

@media (max-width: 768px) {
    ul {
        .booking-card {
            @include cardsOpen();
        }
    }
}


h1 {
    margin: 10px 20px;
}